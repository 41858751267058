import { useEffect, useState } from 'react'
import mute from 'assets/mute.png'
import unmute from 'assets/unmute.png'
import Hls from 'hls.js'
import styles from './Video.module.scss'

export enum StreamType {
  LIVE = 'LIVE',
  VOD = 'VOD',
}

interface Props {
  videoUrl: string
  loop?: boolean
  streamType: StreamType
}

const Video = ({ videoUrl, streamType, loop }: Props) => {
  const [muted, setMuted] = useState(true)

  const toggleMute = () => {
    setMuted(!muted)
  }

  const setVideoSource = () => {
    const video = document.getElementById('video') as HTMLVideoElement
    if (!video) return
    if (!videoUrl) return

    const extension = videoUrl.split('.').pop()

    if (streamType === StreamType.LIVE) {
      if (Hls.isSupported() && extension === 'm3u8') {
        const hls = new Hls()
        hls.loadSource(videoUrl)
        hls.attachMedia(video)
      } else {
        video.src = videoUrl
      }
    }

    if (streamType === StreamType.VOD) {
      video.controls = true
      video.src = videoUrl
    }
  }

  useEffect(() => {
    if (videoUrl && streamType) {
      setVideoSource()
    }
  }, [videoUrl, streamType])

  return (
    <div className={styles.videoContainer} id="video-container">
      <video
        id="video"
        width="100%"
        height="100%"
        playsInline
        autoPlay
        loop={loop}
        muted={muted}
      />
      <input
        hidden={streamType === StreamType.VOD}
        alt="mute"
        className={styles.mute}
        type="image"
        id="snd1"
        src={muted ? mute : unmute}
        onClick={toggleMute}
      />
    </div>
  )
}

export default Video

Video.defaultProps = {
  loop: undefined,
}
