export const BUFF_CONFIG = {
  videoContainerQuerySelector: '#buff-video-container',
  streamId: 906,
  player: 'default',
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJCdWZmVXAgU0RLIFVzZXJzIiwiY2lkIjoxMCwiZXhwIjoxNzAwNzc4Njg0LCJpYXQiOjE2MDA3NzUwODQsImlzcyI6IkJ1ZmZVcCIsInJvbGUiOjAsInN1YiI6ImJjZGEzZjMyLWFmZDctNGRhZi05ZTcxLTBlZTcwMjJhYjliYSIsInVpZCI6MjI2MDIwfQ.AobZvlsJgBDU-FzSZXX7yEM1KbFuSCLLrTV1m4ZG3G8',
  // pluginType: 'default',
  // force: false,
  // debug: true,
  // ignoreRemoteConfig: false,
  // hideBuffFullscreenButton: false,
  // customization: {
  //   playSoundOnBuff: true,
  //   setDefaultVolume: true,
  //   webController: true,
  //   autoHideMenu: true,
  //   menuPosition: 'right',
  //   buffPosition: 'left',
  //   buffAnimationIn: 'bounceInUp',
  //   buffAnimationOut: 'bounceOutDown',
  //   menuXoffset: 5,
  //   menuYoffset: 5,
  //   buffXoffset: 5,
  //   buffYoffset: 5,
  // }
}
