import React, { useEffect, useState, CSSProperties, useRef } from 'react'
import { generateUUID, getUUID, setUUID } from 'utils/uuid'
import firebase from 'utils/firebase'
import { getToken, getUserToken } from 'services/auth'
import { Login } from 'components/Login'
import { Video } from 'components/Video'
import { StreamType } from 'components/Video/Video'
import { useLocation } from 'react-router-dom'
import { Header } from 'components/Header'
import { BuffInfo } from 'components/BuffInfo'
import { BUFF_CONFIG } from 'config/Buff'
import { REMOTE_FETCH_TIME, DEFAULT_REMOTE_CONFIG } from 'config/Remote'
import MediaQuery from 'react-responsive'
import rotate from 'assets/rotate.png'
import styles from './Home.module.scss'

const TARGET = process.env.REACT_APP_TARGET

const remoteConfig = firebase.remoteConfig()
remoteConfig.settings = {
  fetchTimeoutMillis: REMOTE_FETCH_TIME,
  minimumFetchIntervalMillis: REMOTE_FETCH_TIME,
}

remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG

const videoContainerStyles: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
}

interface Props {
  vodName?: string
  isQA?: boolean
  showInfoColumn?: boolean
  greenScreen?: boolean
}

const getStreamOptions = (
  videoID: string | null,
  isQA = false,
  greenScreen = false,
  remoteOptions: any
) => {
  if (videoID) {
    return remoteOptions?.VOD?.[videoID]
  }

  if (isQA) {
    return remoteOptions?.QA
  }

  if (greenScreen) {
    return remoteOptions?.greenscreen
  }

  return remoteOptions?.LIVE
}

const Home = ({ vodName, isQA, showInfoColumn, greenScreen }: Props) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const videoID = vodName || params?.get('videoID')
  const [hasUUID, setHasUUID] = useState(true)
  const [clientToken, setClientToken] = useState('')
  const [stream, setStream] = useState(null)
  const [streamType, setStreamType] = useState(StreamType.LIVE)
  const [videoUrl, setVideoUrl] = useState('')
  const [isLoop, setIsLoop] = useState(undefined)
  const [poster, setPoster] = useState(undefined)
  const [loadingRemote, setLoadingRemote] = useState(false)
  const [allowedVODIds, setAllowedVODIds] = useState<string[]>([])
  const widgetInstance = useRef<any>()

  const containerBg = !showInfoColumn ? 'white' : ''
  const leftSideClass = showInfoColumn ? 'leftSide' : ''

  const getRemoteConfig = (onConfig: (id: number) => void) => {
    setLoadingRemote(true)
    return remoteConfig.fetchAndActivate().finally(() => {
      const remoteOptions = JSON.parse(
        remoteConfig.getValue(`config_${TARGET}`).asString()
      )
      const vodKeys = Object.keys(remoteOptions?.VOD || {})
      setAllowedVODIds(vodKeys)
      setStreamType(videoID ? StreamType.VOD : StreamType.LIVE)
      const streamOptions = getStreamOptions(
        videoID,
        isQA,
        greenScreen,
        remoteOptions
      )

      const loop = streamOptions?.loop
      const video = streamOptions?.video_url
      const streamId = streamOptions?.stream_id
      const posterUrl = streamOptions?.poster?.url
      setStream(streamId)
      setVideoUrl(video)
      setPoster(posterUrl)
      setLoadingRemote(false)
      if (loop) setIsLoop(loop)
      if (streamId) {
        onConfig(streamId)
      }
    })
  }

  const startBuff = async (_config: any) => {
    if (window.SportBuff) {
      if (widgetInstance.current) {
        widgetInstance.current.destroy()
      }
      const instance = await window?.SportBuff.init({
        ..._config,
        addFullScreenButton: true,
      })

      widgetInstance.current = instance
    } else {
      setTimeout(() => {
        startBuff(_config)
      }, 2000)
    }
  }

  // const startBuff = (_config: any) => {
  //   if (window.BuffVideo) {
  //     window?.BuffVideo({ ..._config });
  //   } else {
  //     setTimeout(() => {
  //       startBuff(_config);
  //     }, 2000);
  //   }
  // };

  const initBuff = async (_config: any) => {
    try {
      const userToken = await getToken()
      setClientToken(userToken)
      startBuff({ ..._config, token: userToken })
    } catch (err) {
      console.error(err)
    }
  }

  const onLoginSuccess = (token: string, uuid: string) => {
    setClientToken(token)
    if (!videoID) {
      setUUID(uuid)
    }
    setHasUUID(true)
    getRemoteConfig((id: number) => {
      setTimeout(() => {
        startBuff({
          ...BUFF_CONFIG,
          token,
          streamId: id,
        })
      }, 200)
    })
  }

  const checkUserAuth = async () => {
    if (vodName || !showInfoColumn) {
      const uuid = generateUUID()
      try {
        const response = await getUserToken(uuid)
        onLoginSuccess(response.data.token, uuid)
      } catch (err) {
        console.log(err)
      }
      return
    }

    if (videoID) {
      setHasUUID(false)
      return
    }

    const uuid = getUUID()
    setHasUUID(!!uuid)

    if (uuid) {
      getRemoteConfig((id: number) => {
        setTimeout(() => {
          initBuff({ ...BUFF_CONFIG, streamId: id })
        }, 100)
      })
    }
  }

  useEffect(() => {
    checkUserAuth()
  }, [])

  const NotFound = () => {
    if (vodName && !allowedVODIds.length) return <></>

    return (
      <>
        {!loadingRemote && videoID && !allowedVODIds.includes(videoID) && (
          <>
            <p className={styles.videoNotFound}>
              No stream found with given videoID
            </p>
          </>
        )}
      </>
    )
  }

  const VideoContainer = ({ type, url }: any) => {
    return (
      <>
        {!loadingRemote &&
          (!videoID || (videoID && allowedVODIds.includes(videoID))) && (
            <div id="buff-video-container" style={videoContainerStyles}>
              {poster && <img className="adImage" alt="ad" src={poster} />}
              <Video streamType={type} videoUrl={url} loop={isLoop} />
            </div>
          )}
      </>
    )
  }

  const handleMediaQueryChange = (matches: boolean) => {
    if (matches) {
      setTimeout(() => {
        startBuff({
          ...BUFF_CONFIG,
          token: clientToken,
          streamId: stream,
          force: true,
        })
      }, 1000)
    }
  }

  if (greenScreen) {
    return (
      <>
        <Header />
        <div
          id="buff-video-container"
          style={{ backgroundColor: '#00FF00', height: 'calc(100vh - 60px)' }}
        />
      </>
    )
  }

  return (
    <>
      <Header />
      <div className={styles.content}>
        <Login onLoginSuccess={onLoginSuccess} show={!hasUUID} />

        {hasUUID && (
          <>
            <MediaQuery
              orientation="landscape"
              onChange={handleMediaQueryChange}
            >
              <MediaQuery maxDeviceWidth={1000}>
                <div className="leftSide" style={{ width: '100%' }}>
                  <VideoContainer type={streamType} url={videoUrl} />
                </div>
              </MediaQuery>

              <MediaQuery minDeviceWidth={1001}>
                {showInfoColumn && (
                  <div className="leftSide">
                    <BuffInfo />
                  </div>
                )}

                <div
                  className="rightSide"
                  style={{ backgroundColor: containerBg }}
                >
                  <VideoContainer type={streamType} url={videoUrl} />
                  <NotFound />
                </div>
              </MediaQuery>
            </MediaQuery>

            <MediaQuery
              orientation="portrait"
              onChange={handleMediaQueryChange}
            >
              <div className={leftSideClass}>
                {showInfoColumn && <BuffInfo />}
                <MediaQuery maxDeviceWidth={550}>
                  <VideoContainer type={streamType} url={videoUrl} />
                  <div className="rotateImage">
                    <img alt="rotate" src={rotate} />
                  </div>
                </MediaQuery>
              </div>

              <MediaQuery minDeviceWidth={551}>
                <div className="rightSide">
                  <VideoContainer type={streamType} url={videoUrl} />
                  <NotFound />
                </div>
              </MediaQuery>
            </MediaQuery>
          </>
        )}
      </div>
    </>
  )
}

Home.defaultProps = {
  vodName: undefined,
  isQA: false,
  greenScreen: false,
  showInfoColumn: true,
}

export default Home
