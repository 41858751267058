import fifa from 'assets/fifa.png'
import dreamhack from 'assets/dreamhack.png'
import esl from 'assets/esl.png'
import formulaE from 'assets/formula-e.png'
import ata from 'assets/ata.png'
import fiba from 'assets/fiba.png'
import contact from 'assets/contact.png'
import phone from 'assets/phone.png'
import author from 'assets/author.png'
import { FormEvent, useState } from 'react'
import { generateUUID } from 'utils/uuid'
import { getUserToken } from 'services/auth'
import MediaQuery from 'react-responsive'
import styles from './Login.module.scss'

interface Props {
  show: boolean
  onLoginSuccess: (token: string, uuid: string) => void
}

const Login = ({ show, onLoginSuccess }: Props) => {
  let reload = false
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [loading, setLoading] = useState(false)

  const authUserAndInitBuff = async (_email: string, _username: string) => {
    setLoading(true)
    const uuid = generateUUID()
    try {
      const response = await getUserToken(uuid, _email, _username)
      setLoading(false)
      onLoginSuccess(response.data.token, uuid)
    } catch (err) {
      if (reload) {
        reload = false
        authUserAndInitBuff('', _username)
      } else {
        setLoading(false)
      }
    }
  }

  const onContact = () => {
    window.open('mailto:benn@sportbuff.com')
  }

  const onSubmit = (e: FormEvent) => {
    e.stopPropagation()
    e.preventDefault()
    reload = true
    authUserAndInitBuff(email, username)
  }

  const Author = () => {
    return (
      <>
        <img alt="phone-icon" className={styles.rightSide__img} src={phone} />
        <div className={styles.rightSide__author}>
          <img alt="author" className={styles.authorImg} src={author} />
          <div className={styles.authorContent}>
            <p>
              “Adding Buff was seamless from a practical side and made a big
              impact to audience retention”
            </p>
            <strong>Formula E Accelerate, Project Lead</strong>
          </div>
        </div>
      </>
    )
  }

  if (!show) return null

  return (
    <div className={styles.loginContainer}>
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <h1 className={styles.leftSide__title}>
            Play a VOD demo of Sport Buff
          </h1>
          <p className={styles.leftSide__subtitle}>
            Sign up for a free demo of the Sport Buff Web SDK experience. Take
            part in polls, quizzes and predictions and top the leaderboard.
          </p>
          <form onSubmit={onSubmit} className={styles.leftSide__form}>
            <div className={styles.inputContainer}>
              <label htmlFor="email">Work email</label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id="email"
                type="email"
                placeholder="Work email"
                required
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="username">Demo username</label>
              <input
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                id="username"
                type="text"
                placeholder="Demo username"
                required
                minLength={3}
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className={styles.inputContainer__button}
            >
              {loading ? 'Loading...' : 'Start VOD Demo'}
            </button>
          </form>

          <MediaQuery minWidth={551}>
            <div className={styles.leftSide__contact}>
              <p className={styles.contactTitle}>
                <strong>Get a tour of the product </strong>
              </p>
              <div className={styles.contactCard}>
                <img
                  alt="contact-person"
                  className={styles.contactImg}
                  src={contact}
                />
                <div className={styles.contactInfo}>
                  <strong>Benn Achilleas</strong>
                  <p>Founder CEO at Sport Buff</p>
                </div>
                <button
                  type="button"
                  onClick={onContact}
                  className={styles.contactButton}
                >
                  Get in touch
                </button>
              </div>
            </div>
          </MediaQuery>

          <MediaQuery maxWidth={550}>
            <div className={styles.leftSide__author}>
              <Author />
            </div>
          </MediaQuery>
        </div>
        <div className={styles.rightSide}>
          <Author />
          <div className={styles.rightSide__partners}>
            <div className={styles.imgContainer}>
              <img alt="fifa" height="28px" src={fifa} />
            </div>
            <div className={styles.imgContainer}>
              <img alt="dreamhack" height="11px" src={dreamhack} />
            </div>
            <div className={styles.imgContainer}>
              <img alt="esl" height="36px" src={esl} />
            </div>
            <div className={styles.imgContainer}>
              <img alt="fiba" height="52px" src={fiba} />
            </div>
            <div className={styles.imgContainer}>
              <img alt="ata" height="51px" src={ata} />
            </div>
            <div className={styles.imgContainer}>
              <img alt="formula-e" height="64px" src={formulaE} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
