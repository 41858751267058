import logo from 'assets/sportbuff.png'
import styles from './Header.module.scss'

const Header = () => {
  return (
    <div className={styles.header}>
      <img alt="logo" className={styles.headerLogo} src={logo} />
      <h2 className={styles.headerTitle}>Get started with Sport Buff</h2>
    </div>
  )
}

export default Header
