import { useEffect } from 'react'
import styles from './TwitchVideo.module.scss'

interface Props {
  channel: string
}

const TwitchVideo = ({ channel }: Props) => {
  const setVideoSource = () => {
    new (window as any).Twitch.Embed('video-container', {
      width: '100%',
      height: '100%',
      layout: 'video',
      autoplay: true,
      allowfullscreen: false,
      channel,
    })
  }

  useEffect(() => {
    if (channel) {
      setVideoSource()
    }
  }, [channel])

  return <div className={styles.videoContainer} id="video-container" />
}

export default TwitchVideo
