const BUFF_UUID_KEY = 'buff-uuid'

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const getOrCreateUUID = () => {
  let uuid = localStorage.getItem(BUFF_UUID_KEY)
  if (!uuid) {
    uuid = generateUUID()
    localStorage.setItem(BUFF_UUID_KEY, uuid)
  }
  return uuid
}

export const getUUID = () => {
  return localStorage.getItem(BUFF_UUID_KEY)
}

export const setUUID = (uuid: string) => {
  localStorage.setItem(BUFF_UUID_KEY, uuid)
}

export const removeUUID = () => {
  localStorage.removeItem(BUFF_UUID_KEY)
}
