import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { Home } from 'screens/Home'
import { Jwplayer } from 'screens/Jwplayer'
import { Twitch } from 'screens/Twitch'
import { Research } from 'screens/Research'

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/research">
        <Research />
      </Route>
      <Route path="/live">
        <Twitch />
      </Route>
      <Route exact path="/jwplayer">
        <Jwplayer />
      </Route>
      <Route exact path="/meetbeat-england-netball">
        <Home vodName="MEETBEAT" showInfoColumn={false} />
      </Route>
      <Route exact path="/demo">
        <Home showInfoColumn={false} greenScreen />
      </Route>
      <Route exact path="/qa">
        <Home isQA />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  </Router>
)

export default Routes
