import firebase from 'utils/firebase'
import { getOrCreateUUID } from 'utils/uuid'

const authenticate = firebase.functions().httpsCallable('authenticate')
const getClientToken = firebase.functions().httpsCallable('getClientToken')

const API_URL = process.env.REACT_APP_API_URL
const TARGET = process.env.REACT_APP_TARGET

const getTokenFromSecret = () => {
  return getClientToken({ url: API_URL, target: TARGET })
    .then((res) => {
      return res
    })
    .catch((err) => {
      throw err
    })
}

export const getUserToken = (uuid: string, email = '', username = '') => {
  const body: UserTokenRequest = {
    uuid,
  }

  if (email) {
    body.email = email
  }

  if (username) {
    body.username = username
  }

  return getTokenFromSecret().then((res) => {
    return authenticate({ url: API_URL, body, token: res.data?.token })
      .then((response) => {
        return response
      })
      .catch((err) => {
        throw err
      })
  })
}

export const getToken = async () => {
  const uuid = getOrCreateUUID()

  try {
    const data = await getUserToken(uuid)
    return data.data.token
  } catch (err) {
    console.error(err)
    throw err
  }
}

interface UserTokenRequest {
  uuid: string
  email?: string
  username?: string
}
