import Routes from 'config/Router'
import styles from './App.module.scss'

declare global {
  interface Window {
    BuffVideo: any
    SportBuff: any
  }
}

const App = () => {
  return (
    <div className={styles.App}>
      <Routes />
    </div>
  )
}

export default App
