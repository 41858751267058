import React, { useEffect, useState, useRef } from 'react'
import ReactJWPlayer from 'react-jw-player'
import { getUUID, setUUID } from 'utils/uuid'
import firebase from 'utils/firebase'
import { getToken } from 'services/auth'
import { Login } from 'components/Login'
import { StreamType } from 'components/Video/Video'
import { useLocation } from 'react-router-dom'
import { Header } from 'components/Header'
import { BuffInfo } from 'components/BuffInfo'
import { BUFF_CONFIG } from 'config/Buff'
import { REMOTE_FETCH_TIME, DEFAULT_REMOTE_CONFIG } from 'config/Remote'
import styles from './Jwplayer.module.scss'

const TARGET = process.env.REACT_APP_TARGET

const remoteConfig = firebase.remoteConfig()
remoteConfig.settings = {
  fetchTimeoutMillis: REMOTE_FETCH_TIME,
  minimumFetchIntervalMillis: REMOTE_FETCH_TIME,
}

remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG

const JW_PLAYER_CONFIG = {
  ...BUFF_CONFIG,
  player: 'jwplayer',
  addFullScreenButton: false,
}

const Jwplayer = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const videoID = params?.get('videoID')
  const [hasUUID, setHasUUID] = useState(false)
  const [clientToken, setClientToken] = useState('')
  const [stream, setStream] = useState(null)
  const [streamType, setStreamType] = useState(StreamType.LIVE)
  const [videoUrl, setVideoUrl] = useState('')
  const [poster, setPoster] = useState(undefined)
  const [loadingRemote, setLoadingRemote] = useState(false)
  const [allowedVODIds, setAllowedVODIds] = useState<string[]>([])

  const [playerInstance, setPlayerInstance] = useState()
  const widgetInstance = useRef<any>()

  const jwPlayerId = 'my-unique-id'

  const getRemoteConfig = () => {
    setLoadingRemote(true)
    return remoteConfig.fetchAndActivate().finally(() => {
      const remoteOptions = JSON.parse(
        remoteConfig.getValue(`config_${TARGET}`).asString()
      )
      const vodKeys = Object.keys(remoteOptions?.VOD || {})
      setAllowedVODIds(vodKeys)
      setStreamType(videoID ? StreamType.VOD : StreamType.LIVE)
      const streamOptions = videoID
        ? remoteOptions?.VOD?.[videoID]
        : remoteOptions?.LIVE
      const video = streamOptions?.video_url
      const streamId = streamOptions?.stream_id
      const posterUrl = streamOptions?.poster?.url
      setStream(streamId)
      setVideoUrl(video)
      setPoster(posterUrl)
      setLoadingRemote(false)
    })
  }

  const startBuff = async (_config: any) => {
    if (window.SportBuff) {
      if (widgetInstance.current) {
        widgetInstance.current.destroy()
      }
      const instance = await window?.SportBuff.init({
        ..._config,
        addFullScreenButton: true,
      })

      widgetInstance.current = instance
    } else {
      setTimeout(() => {
        startBuff(_config)
      }, 2000)
    }
  }

  const checkUserAuth = async () => {
    if (videoID) {
      setHasUUID(false)
      return
    }

    const uuid = getUUID()
    setHasUUID(!!uuid)

    if (uuid) {
      const userToken = await getToken()
      setClientToken(userToken)
    }
  }

  const onLoginSuccess = (token: string, uuid: string) => {
    setClientToken(token)
    if (!videoID) {
      setUUID(uuid)
    }
    setHasUUID(true)
  }

  const onJWPlayerReady = () => {
    const player = (window as any).jwplayer(jwPlayerId)
    setPlayerInstance(player)
  }

  useEffect(() => {
    checkUserAuth()
  }, [])

  useEffect(() => {
    if (hasUUID) {
      getRemoteConfig()
    }
  }, [hasUUID])

  useEffect(() => {
    const loadSDK = clientToken && playerInstance && stream
    if (loadSDK) {
      startBuff({
        ...JW_PLAYER_CONFIG,
        token: clientToken,
        jwplayer: playerInstance,
        streamId: stream,
      })
    }
  }, [clientToken, playerInstance, stream])

  const NotFound = () => {
    return (
      <>
        {!loadingRemote && videoID && !allowedVODIds.includes(videoID) && (
          <>
            <p className={styles.videoNotFound}>
              No stream found with given videoID
            </p>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <Header />
      <div className={styles.content}>
        <Login onLoginSuccess={onLoginSuccess} show={!hasUUID} />

        {hasUUID && (
          <section className={styles.section}>
            <div className={styles.leftSide}>
              <BuffInfo />
            </div>

            <div className={styles.rightSide}>
              <div
                id="video-container"
                style={{ position: 'relative', width: '100%' }}
              >
                <ReactJWPlayer
                  playerId="my-unique-id"
                  playerScript="https://content.jwplatform.com/libraries/jvJ1Gu3c.js"
                  file={videoUrl}
                  onReady={onJWPlayerReady}
                />
              </div>
              <NotFound />
            </div>
          </section>
        )}
      </div>
    </>
  )
}

export default Jwplayer
