import { CSSProperties, useEffect, useState } from 'react'
import { getUUID, setUUID } from 'utils/uuid'
import firebase from 'utils/firebase'
import { getToken } from 'services/auth'
import { Login } from 'components/Login'
import { TwitchVideo } from 'components/TwitchVideo'
import { Header } from 'components/Header'
import { BuffInfo } from 'components/BuffInfo'
import { BUFF_CONFIG } from 'config/Buff'
import { REMOTE_FETCH_TIME, DEFAULT_REMOTE_CONFIG } from 'config/Remote'
import styles from './Twitch.module.scss'

const TARGET = process.env.REACT_APP_TARGET

const remoteConfig = firebase.remoteConfig()
remoteConfig.settings = {
  fetchTimeoutMillis: REMOTE_FETCH_TIME,
  minimumFetchIntervalMillis: REMOTE_FETCH_TIME,
}

remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG

const Twitch = () => {
  const [hasUUID, setHasUUID] = useState(true)
  const [channel, setChannel] = useState('')
  const [poster, setPoster] = useState(undefined)
  const [loadingRemote, setLoadingRemote] = useState(false)

  const getRemoteConfig = (onConfig: (id: number) => void) => {
    setLoadingRemote(true)
    return remoteConfig.fetchAndActivate().finally(() => {
      const remoteOptions = JSON.parse(
        remoteConfig.getValue(`config_${TARGET}`).asString()
      )
      const streamOptions = remoteOptions?.TWITCH
      const twitchChannel = streamOptions?.channel
      const streamId = streamOptions?.stream_id
      const posterUrl = streamOptions?.poster?.url
      setChannel(twitchChannel)
      setPoster(posterUrl)
      setLoadingRemote(false)
      if (twitchChannel && streamId) {
        onConfig(streamId)
      }
    })
  }

  const startBuff = async (_config: any) => {
    if (window.SportBuff) {
      await window?.SportBuff.init({
        ..._config,
        addFullScreenButton: true,
      })
    } else {
      setTimeout(() => {
        startBuff(_config)
      }, 2000)
    }
  }

  const initBuff = async (_config: any) => {
    try {
      const userToken = await getToken()
      startBuff({ ..._config, token: userToken })
    } catch (err) {
      console.error(err)
    }
  }

  const checkUserAuth = () => {
    const uuid = getUUID()
    setHasUUID(!!uuid)

    if (uuid) {
      getRemoteConfig((id: number) => {
        setTimeout(() => {
          initBuff({ ...BUFF_CONFIG, streamId: id })
        }, 100)
      })
    }
  }

  const onLoginSuccess = (token: string, uuid: string) => {
    setUUID(uuid)
    setHasUUID(true)
    getRemoteConfig((id: number) => {
      setTimeout(() => {
        startBuff({
          ...BUFF_CONFIG,
          token,
          streamId: id,
        })
      }, 200)
    })
  }

  useEffect(() => {
    checkUserAuth()
  }, [])
  const videoContainerStyles: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: '70%',
    width: '100%',
  }

  return (
    <>
      <Header />
      <div className={styles.content}>
        <Login onLoginSuccess={onLoginSuccess} show={!hasUUID} />
        <>
          <div className="leftSide">
            <BuffInfo />
          </div>
          <div className="rightSide">
            {!loadingRemote && (
              <>
                <div id="buff-video-container" style={videoContainerStyles}>
                  {poster && <img className="adImage" alt="ad" src={poster} />}
                  <TwitchVideo channel={channel} />
                </div>
              </>
            )}
          </div>
        </>
      </div>
    </>
  )
}

export default Twitch
