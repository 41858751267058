import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/remote-config'

const firebaseConfig = {
  apiKey: 'AIzaSyBjEtbKQGBA49GvR8pDI1Pv4zQACcVehug',
  authDomain: 'buffuptv-89e56.firebaseapp.com',
  databaseURL: 'https://buffuptv-89e56.firebaseio.com',
  projectId: 'buffuptv-89e56',
  storageBucket: 'buffuptv-89e56.appspot.com',
  messagingSenderId: '708289783913',
  appId: '1:708289783913:web:5bcc7cf34d6eaf563adaf8',
  measurementId: 'G-F7N007C6DW',
}

firebase.initializeApp(firebaseConfig)

export default firebase
